<template>
  <div
    :class="[
      $viewport.isGreaterOrEquals('sm') && isDarkThem ? 'dark' : '',
      $viewport.isLessThan('sm') && isDarkMobileThem? 'darkMobile' : ''
    ]"
    class="home-slide__container "
  >
    <nuxt-img
      :placeholder="!isComponentMounted"
      class="main-home-swiper__img"
      :src="String(imageId)"
      :width="$viewport.isLessThan('md') ? 860 : 1900"
      :height="$viewport.isLessThan('md') ? props.minHeightMobile : props.minHeightDesktop"
      alt="slider"
      :fit="$viewport.isLessThan('md') ? 'contain' : 'crop'"
      loading="lazy"
    />
    <div class="link-absolute">
      <div v-if="!isComponentMounted" />
      <div
        v-else
        class="container"
      >
        <div
          :class="[]"
          class="main-img__content"
        >
          <component
            :is="idx === 0 ?'h1':'div'"
            :class="[alignmentTitleContent]"
            class="main-slider__title cms-block-stub"
            v-html="slide.title"
          />

          <div
            v-if="slide.subtitle"
            :class="[alignmentSubtitleContent]"
            class="main-slider__subtitle cms-block-stub"
            v-html="slide.subtitle"
          />

          <div
            v-if="slide?.text"
            :class="[alignmentTextContent]"
            class="main-slider__text cms-block-stub"
            v-html="slide?.text"
          />

          <div
            :class="[alignmentBtnsContent]"
            class="main-slider__btns"
          >
            <NuxtLink
              v-if="slide?.form_button_label && slide?.button_type"
              :to="slide?.link || $route.path"
              class="main-slider__btn btn primary --shadow banner-btn"
              @click.capture="onHandlerLink"
            >
              {{ slide.form_button_label }}
            </NuxtLink>

            <div
              v-if="videoLink"
              class="main-btn__video"
              @click="openVideo"
            >
              <span>Видео</span>
              <div class="main-slider__video btn video --shadow">
                <img
                  src="~/assets/img/svg/common/play.svg"
                  alt="play"
                >
              </div>
            </div>
            <IFlatBtn
              v-if="slide.widget_enable"
              :text="slide.widget_text"
            />
          </div>

          <div
            v-if="slide.tags && slide.tags.length"
            :class="[alignmentTagsContent]"
            class="main-slider__tags"
          >
            <div
              v-for="tag in slide.tags"
              :key="`main-slider__tag-${tag.title}`"
              class="tag"
            >
              <span>{{ tag.title }}</span>
              <NuxtLink
                v-if="tag.link"
                class="link-absolute"
                :to="tag.link"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ItemsValuesMainSliderBlock } from '~/types/SliderBlocksType';
import { useCallBackPopup } from '~/composables/useCallBackPopup';
import { useVideoplayerPopup } from '#imports';

const { openCallBackPopup } = useCallBackPopup();
const { openVideoplayerPopup } = useVideoplayerPopup();
const $viewport = useViewport();
const $route = useRoute();

const props = defineProps<{
	slide: ItemsValuesMainSliderBlock;
	videoLink?: string;
	minHeightDesktop: number;
	minHeightMobile: number;
	idx: number;
}>();

const isComponentMounted = ref<boolean>(false);

const isDarkThem = computed(() => props.slide.theme_color === 'dark');
const isDarkMobileThem = computed(() => props.slide.theme_color_mobile === 'dark');

const isHome = computed(() => $route.path === '/');

const imageId = computed(() => {
  if ($viewport.isLessThan('md') && props.slide.image_mobile) {
    return props.slide.image_mobile;
  }

  return props.slide.image;
});

const onHandlerLink = (event: Event) => {
  if (props.slide.link) {
    return;
  }

  event.preventDefault();

  openCallBackPopup({
    title: props.slide?.form_title || 'Получить консультацию',
    subtitle: props.slide?.form_message,
    type: props.slide?.form_type,
    btnLabel: props.slide?.form_button_label,
    ymSendForm: props.slide?.form_type
  });
};

const openVideo = () => {
  openVideoplayerPopup({
    videoLink: props.videoLink
  });
};

const alignmentTitleContent = computed(() => props.slide?.items_align?.find((t) => t.element === 'title')?.alignment || 'left');
const alignmentSubtitleContent = computed(() => props.slide?.items_align?.find((t) => t.element === 'subtitle')?.alignment || 'left');
const alignmentTextContent = computed(() => props.slide?.items_align?.find((t) => t.element === 'text')?.alignment || 'left');
const alignmentBtnsContent = computed(() => props.slide?.items_align?.find((t) => t.element === 'button')?.alignment || 'left');
const alignmentTagsContent = computed(() => props.slide?.items_align?.find((t) => t.element === 'tags')?.alignment || 'left');

onMounted(() => {
  isComponentMounted.value = true;
});
</script>

<style lang="scss">
@import "@/assets/scss/media";

body.sale {
	& .home-slide__container {
		margin-top: 105px;
	}
}

body.sale-slider__padding {
	& .home-slide__container {
		padding-bottom: 105px;
	}
}

body.sale-slider__padding .not-parallax {
	& .home-slide__container {
		padding-bottom: 0;
	}
}

.home-slide__container {
	width: 100%;
	height: 100%;
	position: relative;
	margin-top: 60px;
	padding-bottom: 60px;
}

.home-slide__container.dark {
	.main-slider__title,
	.main-slider__title > * {
		color: var(--primary);
	}

	.main-slider__subtitle,
	.main-slider__subtitle > * {
		color: var(--primary);
	}

	.main-slider__text,
	.main-slider__text > * {
		color: var(--primary);
	}

	.main-slider__tags {
		& > .tag {
			box-shadow: 0 -0.2px 0 0.8px #cdc3c3;
			color: var(--primary);
			background: rgb(255 255 255 / 60%);
			position: relative;
		}
	}
}

.main-home-swiper__img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.main-slider__tags {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 8px;

	& > .tag {
		width: fit-content;
		padding: 8px 18px;
		border-radius: 48px;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 16px;
		letter-spacing: -0.14px;
		color: var(--secondary);
		box-shadow: 0 -0.2px 0 0.8px #847699;
		background: rgba(255 255 255 / 15%);
	}
}

.main-img__content {
	max-width: 100%;

	& .marker {
		color: var(--secondary);
	}
}

.main-slider__title {
	margin: 40px 0;
	display: flex;
	flex-direction: column;
}

.main-slider__title,
.main-slider__title > *,
.main-slider__title > * > * {
	color: var(--white);
	font-size: 96px;
	font-style: normal;
	font-weight: 700;
	line-height: 115%; /* 110.4px */

	& > p, h1, h2, h3, h4 {
		margin: 0;
	}
}

.main-slider__subtitle {
	margin-bottom: 39px;
	display: flex;
	flex-direction: column;
}

.main-slider__subtitle,
.main-slider__subtitle > * {
	color: var(--white);
	font-size: 36px;
	font-style: normal;
	font-weight: 700;
	line-height: 115%; /* 39.6px */

	& > p, h1, h2, h3, h4 {
		margin: 0;
	}
}

.main-slider__text {
	margin-bottom: 26px;
	display: flex;
	flex-direction: column;
}

.main-slider__text,
.main-slider__text > * {
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 130%;
	letter-spacing: -0.48px;
	color: var(--white);

	& > p, h1, h2, h3, h4 {
		margin: 0;
	}
}

.main-slider__btns {
	display: flex;
	align-items: flex-end;
	gap: 16px;
	margin-bottom: 18px;
}

.main-slider__btn {
	height: 50px;
	min-width: 280px;
	font-size: 18px;
}

.main-btn__video {
	display: flex;
	align-items: center;
	gap: 16px;
	border-radius: 30px;
	background: rgb(99 74 138 / 50%);
	backdrop-filter: blur(20px);
	padding-left: 20px;
	transition: all .3s linear;
	cursor: pointer;

	&:hover {
		opacity: .85;
	}

	& > span {
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px; /* 100% */
		letter-spacing: -0.4px;
		color: var(--white);
	}
}

.main-slider__title.right,
.main-slider__subtitle.right,
.main-slider__text.right,
.main-slider__btns.right,
.main-slider__tags.right {
	justify-content: flex-end;
	text-align: end;

}

.main-slider__title.center,
.main-slider__subtitle.center,
.main-slider__text.center,
.main-slider__btns.center,
.main-slider__tags.center {
	justify-content: center;
	text-align: center;

}

@include media('xl'){
	.main-slider__title {
	}
}

//@include media('md') {
//	.main-slider__title {
//		font-size: 58px;
//		line-height: 106%;
//	}
//
//	.main-slider__subtitle {
//		font-size: 30px;
//	}
//
//	.main-slider__text {
//		font-size: 20px;
//	}
//}

@include media('md') {
	//.darkMobile {
	//	.main-slider__title {
	//		color: var(--primary);
	//	}
	//
	//	.main-slider__subtitle {
	//		color: var(--primary);
	//	}
	//
	//	.main-slider__text {
	//		color: var(--primary);
	//	}
	//
	//	.main-slider__tags {
	//		& > .tag {
	//			box-shadow: 0 -0.2px 0 0.8px #cdc3c3;
	//			color: var(--primary);
	//			background: rgb(255 255 255 / 60%);
	//		}
	//	}
	//}
	body.sale-slider__padding {
		& .main-img__content {
			padding-bottom: 300px;

			//padding-bottom: 170px;
		}
	}

	body.sale-slider__padding .not-parallax {
		& .main-img__content {
			padding-bottom: 110px;
		}
	}

	.home-slide__container {
		& .container {
			height: 100%;
		}
	}

	.main-img__content {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding-bottom: 110px;
		justify-content: flex-end;
	}

	.main-slider__title {
		margin: 10px 0;
	}

	.main-slider__title,
	.main-slider__title > *,
	.main-slider__title > * > * {
		font-size: 36px !important;
		font-style: normal;
		font-weight: 700;
		line-height: 115%;
		letter-spacing: -1.44px;
	}

	.main-slider__subtitle {
		margin-bottom: 24px;
	}

	.main-slider__subtitle,
	.main-slider__subtitle > * {
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
		letter-spacing: -0.96px;
	}

	.main-slider__text {
		margin-bottom: 15px;
	}

	.main-slider__text,
	.main-slider__text > * {
		font-size: 16px;
		font-weight: 600;
		line-height: 130%;
	}

	.main-slider__btns {
		margin-bottom: 26px;
		flex-wrap: wrap;
	}

	.main-slider__btn {
		height: 48px;
		max-width: 268px;
		min-width: 268px;
	}

	.main-btn__video {
		flex-direction: column;
		align-items: center;
		gap: 6px;
		transition: all 0.3s linear;
		padding-left: 0;
		background: initial;
		backdrop-filter: initial;

		& > span {
			font-size: 10px;
			line-height: 130%; /* 13px */
			letter-spacing: 1.3px;
			text-transform: uppercase;
		}
	}

	.main-slider__tags {
		justify-content: center;

		& > .tag {
			padding: 4.5px 14px;
			font-size: 12px;
			font-style: normal;
			font-weight: 700;
			line-height: 16px; /* 160% */
			letter-spacing: -0.1px;
			backdrop-filter: initial;
		}
	}
}

@include media('md') {
	.home-slide__container.darkMobile {
		.main-slider__title,
		.main-slider__title > * {
			color: var(--primary);
		}

		.main-slider__subtitle,
		.main-slider__subtitle > * {
			color: var(--primary);
		}

		.main-slider__text,
		.main-slider__text > * {
			color: var(--primary);
		}

		.main-slider__tags {
			& > .tag {
				box-shadow: 0 -0.2px 0 0.8px #cdc3c3;
				color: var(--primary);
				background: rgb(255 255 255 / 60%);
				position: relative;
			}
		}
	}
}

</style>
